import { createSettingsParams } from '@wix/tpa-settings';
import { appName } from '../../../.application.json';

export type ISettingsParams = {
  greetingsText: string;
};

export default createSettingsParams<ISettingsParams>({
  greetingsText: {
    getDefaultValue: ({ t }) =>
      `${t('app.settings.defaults.greetingsPretext')} ${appName}`,
  },
});
