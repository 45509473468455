import React from 'react';
import {
  withExperiments,
  translate,
  InjectedExperimentsProps,
  InjectedTranslateProps,
} from 'yoshi-flow-editor-runtime';
import { Button } from 'wix-ui-tpa/Button';
import { useSettings } from '@wix/tpa-settings/dist/src/hooks';
import settingsParams from '../settingsParams';
import { st, classes } from './Widget.st.css';

type WidgetProps = InjectedExperimentsProps &
  InjectedTranslateProps & {
    className?: string;
  };

export default translate()(
  withExperiments<WidgetProps>(({ t, experiments, className }) => {
    const settings = useSettings();
    console.log(settings);

    return (
      <div
        className={st(classes.root, {}, className)}
        data-hook="schedule-wrapper"
      >
        <div className={classes.header}>
          <h2 data-hook="app-title">
            {t('app.widget.welcome')}{' '}
            {settings.get(settingsParams.greetingsText)}!
          </h2>
        </div>
        {experiments.enabled('specs.test.ShouldHideButton') ? null : (
          <Button className={classes.mainButton}>Click me</Button>
        )}
      </div>
    );
  }),
);
